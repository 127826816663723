import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Leer el estado de darkMode de localStorage, si existe
const getInitialDarkMode = (): boolean => {
  const storedDarkMode = localStorage.getItem('darkMode');
  return storedDarkMode ? JSON.parse(storedDarkMode) : false;
};

interface ThemeState {
  darkMode: boolean;
}

const initialState: ThemeState = {
  darkMode: getInitialDarkMode(), // Usar el valor de localStorage como estado inicial
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggleDarkMode: (state) => {
      state.darkMode = !state.darkMode;
      localStorage.setItem('darkMode', JSON.stringify(state.darkMode)); // Guardar en localStorage
    },
    setDarkMode: (state, action: PayloadAction<boolean>) => {
      state.darkMode = action.payload;
      localStorage.setItem('darkMode', JSON.stringify(state.darkMode)); // Guardar en localStorage
    },
  },
});

export const { toggleDarkMode, setDarkMode } = themeSlice.actions;

export default themeSlice.reducer;

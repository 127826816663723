import axios, { Method } from 'axios';
import { auth } from './firebaseConfig';  // Firebase cliente

interface ApiRequestParams {
  endpoint: string;
  body?: any;
  params?: Record<string, string>;
  method: Method;
}

const BASE_URL = 'https://southamerica-east1-cg-elevadores.cloudfunctions.net';
// const BASE_URL = 'http://127.0.0.1:5001/cg-elevadores/us-central1';

export const callFirebaseFunction = async ({ endpoint, body, method, params }: ApiRequestParams) => {
  const url = new URL(`${BASE_URL}/${endpoint}`);
  const token = await auth.currentUser?.getIdToken();

  if (params) {
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
  }

  try {
    const response = await axios({
      url: url.toString(),
      method: method,
      data: body,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error calling Firebase Function:', error);
    throw error;
  }
};

import React from 'react';

const PayrollChart: React.FC = () => {
  return (
    <div className="bg-white dark:bg-gray-900 p-4 rounded-lg shadow">
      <h2 className="text-lg font-medium text-gray-800 dark:text-gray-100">Nómina Pagada</h2>
      <div className="mt-4 grid grid-cols-5 gap-4">
        <div className="h-24 bg-blue-500 rounded"></div>
        <div className="h-20 bg-blue-500 rounded"></div>
        <div className="h-22 bg-blue-500 rounded"></div>
        <div className="h-24 bg-blue-500 rounded"></div>
        <div className="h-24 bg-blue-500 rounded"></div>
      </div>
    </div>
  );
};

export default PayrollChart;

import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCvglM6MScZHAoQpox420n1fI9_Fr_XfjM",
  authDomain: "cg-elevadores.firebaseapp.com",
  projectId: "cg-elevadores",
  storageBucket: "cg-elevadores.appspot.com",
  messagingSenderId: "237633581035",
  appId: "1:237633581035:web:6bab6e944d6ce8d89c33ae"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, provider };

import React, { useState, useEffect } from 'react';
import { Building } from "../../types/building";
import { Technician } from "../../types/technician";
import { callFirebaseFunction } from "../../services/api";

interface AddMaintenanceModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (maintenance: any) => void;
  buildings: Building[];
}

const AddMaintenanceModal: React.FC<AddMaintenanceModalProps> = ({
                                                                   isOpen,
                                                                   onClose,
                                                                   onSave,
                                                                   buildings,
                                                                 }) => {
  const [buildingId, setBuildingId] = useState<string | null>(null);
  const [technicianId, setTechnicianId] = useState<string | null>(null);
  const [technicians, setTechnicians] = useState<Technician[]>([]);
  const [date, setDate] = useState<string>('');
  const [elevatorsChecked, setElevatorsChecked] = useState<number>(0);
  const [cost, setCost] = useState<number>(0);
  const [status, setStatus] = useState<string>('Pendiente');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (isOpen) {
      const fetchTechnicians = async () => {
        setLoading(true);
        setError(null);
        try {
          const response = await callFirebaseFunction({
            endpoint: 'getTechnicians',
            method: 'GET',
          });
          setTechnicians(response);
        } catch (err) {
          console.error('Error al obtener los técnicos:', err);
          setError('Error al cargar la lista de técnicos');
        } finally {
          setLoading(false);
        }
      };

      fetchTechnicians();
    }
  }, [isOpen]);

  const handleSave = () => {
    if (buildingId && technicianId && date && cost > 0) {
      const newMaintenance = {
        buildingId,
        technicianId,
        date,
        elevatorsChecked,
        cost,
        status,
      };
      onSave(newMaintenance);
      onClose(); // Cerrar el modal después de guardar
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg max-w-md w-full sm:max-w-lg lg:max-w-xl">
        <h2 className="text-xl font-bold mb-4 dark:text-gray-100">Agregar Mantención Mensual</h2>

        {loading ? (
          <p className="text-center dark:text-gray-300">Cargando técnicos...</p>
        ) : error ? (
          <p className="text-red-500 text-center">{error}</p>
        ) : (
          <>
            <div className="mb-4">
              <label className="block text-gray-700 dark:text-gray-300 mb-2">Edificio</label>
              <select
                className="w-full p-2 border border-gray-300 dark:border-gray-700 rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
                value={buildingId || ''}
                onChange={(e) => setBuildingId(e.target.value)}
              >
                <option value="" disabled>Selecciona un edificio</option>
                {buildings.map((building) => (
                  <option key={building.id} value={building.id}>
                    {building.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 dark:text-gray-300 mb-2">Técnico</label>
              <select
                className="w-full p-2 border border-gray-300 dark:border-gray-700 rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
                value={technicianId || ''}
                onChange={(e) => setTechnicianId(e.target.value)}
              >
                <option value="" disabled>Selecciona un técnico</option>
                {technicians.map((technician) => (
                  <option key={technician.id} value={technician.id}>
                    {technician.firstName}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 dark:text-gray-300 mb-2">Fecha</label>
              <input
                type="date"
                className="w-full p-2 border border-gray-300 dark:border-gray-700 rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 dark:text-gray-300 mb-2">Cantidad de Ascensores Revisados</label>
              <input
                type="number"
                className="w-full p-2 border border-gray-300 dark:border-gray-700 rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
                value={elevatorsChecked}
                onChange={(e) => setElevatorsChecked(Number(e.target.value))}
                min={0}
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 dark:text-gray-300 mb-2">Valor de la Mantención</label>
              <input
                type="number"
                className="w-full p-2 border border-gray-300 dark:border-gray-700 rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
                value={cost}
                onChange={(e) => setCost(Number(e.target.value))}
                min={0}
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 dark:text-gray-300 mb-2">Estado</label>
              <select
                className="w-full p-2 border border-gray-300 dark:border-gray-700 rounded bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="Pendiente">Pendiente</option>
                <option value="En proceso">En proceso</option>
                <option value="Lista">Lista</option>
              </select>
            </div>

            <div className="flex justify-end">
              <button
                onClick={onClose}
                className="bg-gray-500 text-white px-4 py-2 rounded-lg mr-2"
              >
                Cancelar
              </button>
              <button
                onClick={handleSave}
                className="bg-blue-600 text-white px-4 py-2 rounded-lg"
              >
                Guardar
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AddMaintenanceModal;

import React from 'react';

const StatsCards: React.FC = () => {
  return (
    <div className="w-full flex flex-wrap gap-16 justify-center p-4">
      <div className="flex w-full sm:w-1/5 flex-col gap-1 rounded-xl p-6 border border-[#d0dbe7] dark:border-slate-700 bg-white dark:bg-slate-900">
        <p className="text-[#0e141b] dark:text-white text-base font-medium leading-normal">Total</p>
        <p className="text-[#0e141b] dark:text-white tracking-light text-2xl font-bold leading-tight">$53,000</p>
        <p className="text-[#078838] text-base font-medium leading-normal">+10%</p>
      </div>
      <div className="flex w-full sm:w-1/5 flex-col gap-1 rounded-xl p-6 border border-[#d0dbe7] dark:border-slate-700 bg-white dark:bg-slate-900">
        <p className="text-[#0e141b] dark:text-white text-base font-medium leading-normal">Pagado</p>
        <p className="text-[#0e141b] dark:text-white tracking-light text-2xl font-bold leading-tight">$53,000</p>
        <p className="text-[#078838] text-base font-medium leading-normal">+10%</p>
      </div>
      <div className="flex w-full sm:w-1/5 flex-col gap-1 rounded-xl p-6 border border-[#d0dbe7] dark:border-slate-700 bg-white dark:bg-slate-900">
        <p className="text-[#0e141b] dark:text-white text-base font-medium leading-normal">No Pagado</p>
        <p className="text-[#0e141b] dark:text-white tracking-light text-2xl font-bold leading-tight">$0</p>
        <p className="text-[#e73908] text-base font-medium leading-normal">-10%</p>
      </div>
    </div>
  );
};

export default StatsCards;

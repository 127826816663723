import React from 'react';
import Filters from '../components/Payments/Filters';
import PaymentsTable from '../components/Table/PaymentsTable';
import PaymentsByTypeChart from "../components/Payments/PaymentsByType";
import PaymentsByMonthChart from "../components/Payments/PaymentsByMonth";
import StatsCards from "../components/Payments/StatsCards";
import MonthYearPicker from "../components/common/MonthYearPicker";

const PaymentsPage: React.FC = () => {
  return (
    <div className="relative flex size-full flex-col bg-slate-50 dark:bg-slate-800 group/design-root overflow-x-hidden">
      <div className="layout-container flex h-full grow flex-col">
        <div className="px-4 sm:px-6 lg:px-8 flex flex-1 justify-center py-5">
          <div className="layout-content-container flex flex-col w-full max-w-5xl">
            <h1 className="text-[#0e141b] dark:text-white text-3xl font-bold leading-tight mb-4">Pagos</h1>
            <StatsCards/>
            <div className="flex flex-wrap gap-4 px-4 py-6">
              <PaymentsByMonthChart/>
              <PaymentsByTypeChart/>
            </div>
            <Filters/>
            <MonthYearPicker/>
            <PaymentsTable/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentsPage;

import React, { useState, useEffect, CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Button from '../components/common/Button';
import { RootState } from '../redux/store';
import AddBuildingModal from "../components/modals/AddBuildingModal";
import AddMaintenanceModal from "../components/modals/AddMaintenanceModal";
import { callFirebaseFunction } from "../services/api";
import { Building } from "../types/building";
import { PulseLoader } from "react-spinners";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const Buildings: React.FC = () => {
  const navigate = useNavigate();
  const darkMode = useSelector((state: RootState) => state.theme.darkMode);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalMaintenanceOpen, setIsModalMaintenanceOpen] = useState(false);
  const [buildings, setBuildings] = useState<Building[]>([]);
  const [filteredBuildings, setFilteredBuildings] = useState<Building[]>([]);
  const [searchTerm, setSearchTerm] = useState(''); // Estado para el término de búsqueda
  const [loading, setLoading] = useState(true);
  const [color, setColor] = useState("#891515");

  const handleAddBuilding = () => {
    setIsModalOpen(true);
  };

  const handleSaveBuilding = async (building: any) => {
    try {
      const payload = JSON.stringify(building);
      const response = await callFirebaseFunction({ endpoint: 'addBuilding', body: payload, method: 'POST' });
      console.log('Edificio guardado:', response.data);
      fetchBuildings(); // Refrescar la lista de edificios después de agregar uno nuevo
    } catch (error) {
      console.error('Error al guardar el edificio:', error);
    }
  };

  const handleAddMaintenance = () => {
    setIsModalMaintenanceOpen(true);
  };

  const handleSaveMaintenance = async (maintenance: any) => {
    try {
      // Incluye el ID del edificio seleccionado al payload
      const maintenanceWithBuildingId = {
        ...maintenance,
        buildingId: maintenance.buildingId,
      };
      const response = await callFirebaseFunction({
        endpoint: 'addMaintenance',
        body: maintenanceWithBuildingId,
        method: 'POST',
      });
      console.log('Mantención guardada:', response.data);
    } catch (error) {
      console.error('Error al guardar la mantención:', error);
    }
  };

  const fetchBuildings = async () => {
    try {
      const response = await callFirebaseFunction({ endpoint: 'getBuildings', method: 'GET' });
      setBuildings(response);
      setFilteredBuildings(response); // Inicialmente, muestra todos los edificios
      setLoading(false);
    } catch (error) {
      console.error('Error al obtener los edificios:', error);
      setLoading(false);
    }
  };

  // Filtrar edificios cuando el término de búsqueda cambie
  useEffect(() => {
    const results = buildings.filter(building =>
      building.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      building.owner.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredBuildings(results);
  }, [searchTerm, buildings]);

  useEffect(() => {
    fetchBuildings(); // Obtener la lista de edificios cuando el componente se monta
  }, []); // La lista de dependencias está vacía, por lo que solo se ejecutará una vez al montar

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <PulseLoader color={color} loading={loading} cssOverride={override} size={15} margin={2} />
      </div>
    );
  }

  if (buildings.length === 0) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <p className="text-red-500">No se encontró lista de los edificios.</p>
      </div>
    );
  }

  return (
    <div className={`p-4 sm:p-6 bg-white ${darkMode ? 'dark:bg-gray-900 text-gray-900' : ''} ${darkMode ? 'dark:text-gray-100' : ''}`}>
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 gap-4">
        <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100">Edificios</h2>
        <div className="flex flex-col sm:flex-row gap-2">
          <Button
            onClick={handleAddBuilding}
            className="text-white px-4 py-2 rounded-lg"
            text="Agregar Edificio"
          />
          <Button
            onClick={handleAddMaintenance}
            className="text-white px-4 py-2 rounded-lg"
            text="Agregar Mantención"
          />
        </div>
      </div>

      {/* Campo de búsqueda */}
      <div className="mb-6">
        <input
          type="text"
          placeholder="Buscar por nombre del edificio o nombre del dueño..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-lg dark:border-gray-700 dark:bg-gray-800 dark:text-gray-100"
        />
      </div>

      <div className="max-w-4xl mx-auto space-y-4">
        {filteredBuildings.map((building) => (
          <div
            key={building.id}
            className={`flex justify-between items-start gap-4 p-4 rounded-lg shadow ${darkMode ? 'dark:bg-gray-800' : ''}`}
          >
            <div className="flex items-start gap-4">
              <div
                className={`flex items-center justify-center rounded-lg bg-[#e7edf3] ${darkMode ? 'dark:bg-gray-700' : ''} p-3`}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor"
                     viewBox="0 0 256 256">
                  <path
                    d="M240,208H224V96a16,16,0,0,0-16-16H144V32a16,16,0,0,0-24.88-13.32L39.12,72A16,16,0,0,0,32,85.34V208H16a8,8,0,0,0,0,16H240a8,8,0,0,0,0-16ZM208,96V208H144V96ZM48,85.34,128,32V208H48ZM112,112v16a8,8,0,0,1-16,0V112a8,8,0,1,1,16,0Zm-32,0v16a8,8,0,0,1-16,0V112a8,8,0,1,1,16,0Zm0,56v16a8,8,0,0,1-16,0V168a8,8,0,0,1,16,0Zm32,0v16a8,8,0,0,1-16,0V168a8,8,0,0,1,16,0Z"
                  ></path>
                </svg>
              </div>
              <div className="flex flex-col">
                <p className={`text-xl font-bold dark:text-white`}>{building.name}</p>
                <p className={`text-base font-medium dark:text-gray-300`}>{building.address}</p>
                <p className={`text-sm dark:text-gray-300`}>Administrador: {building.owner}</p>
                <p className={`text-sm dark:text-gray-300`}>Último Mantenimiento: {building.lastMaintenance.date}</p>
              </div>
            </div>
            <Button
              onClick={() => navigate(`/buildings/${building.id}`)}
              text="Ver Detalles"
              className={`w-fit ${darkMode ? 'bg-gray-700 text-gray-100' : 'bg-gray-200 text-gray-800'}`}
            />
          </div>
        ))}
      </div>
      <AddBuildingModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSaveBuilding}
      />
      <AddMaintenanceModal
        isOpen={isModalMaintenanceOpen}
        onClose={() => setIsModalMaintenanceOpen(false)}
        onSave={handleSaveMaintenance}
        buildings={buildings}
      />
    </div>
  );
};

export default Buildings;

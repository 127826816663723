import React, { useState } from 'react';
import { Technician } from "../../types/technician";

interface AddTechnicianModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (technician: Technician) => void;
}

const AddTechnicianModal: React.FC<AddTechnicianModalProps> = ({ isOpen, onClose, onSave }) => {
  const [technician, setTechnician] = useState<Technician>({
    firstName: '',
    rut: '',
    email: '',
    phone: '',
    specialization: '',
    salary: '',
    status: 'active',
    hireDate: '',
    contractType: '', // Inicializamos el nuevo campo
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setTechnician((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = () => {
    onSave(technician);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white dark:bg-gray-900 rounded-lg p-6 w-full max-w-md sm:max-w-lg lg:max-w-xl">
        <h2 className="text-xl font-bold mb-4">Agregar Técnico</h2>
        <div className="space-y-4">
          {/* Otros campos aquí */}
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Nombre</label>
            <input
              type="text"
              name="firstName"
              value={technician.firstName}
              onChange={handleChange}
              className="form-input mt-1 block w-full border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-100"
              placeholder="Ingresa el nombre"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">RUT</label>
            <input
              type="text"
              name="rut"
              value={technician.rut}
              onChange={handleChange}
              className="form-input mt-1 block w-full border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-100"
              placeholder="Ingresa el RUT"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Correo</label>
            <input
              type="email"
              name="email"
              value={technician.email}
              onChange={handleChange}
              className="form-input mt-1 block w-full border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-100"
              placeholder="Ingresa el correo"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Teléfono</label>
            <input
              type="text"
              name="phone"
              value={technician.phone}
              onChange={handleChange}
              className="form-input mt-1 block w-full border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-100"
              placeholder="Ingresa el teléfono"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Especialización</label>
            <select
              name="specialization"
              value={technician.specialization}
              onChange={handleChange}
              className="form-select mt-1 block w-full border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-100"
            >
              <option value="">Selecciona una</option>
              <option value="electrician">Electricista</option>
              <option value="mechanic">Mecánico</option>
              <option value="inspector">Inspector</option>
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Sueldo</label>
            <input
              type="text"
              name="salary"
              value={technician.salary}
              onChange={handleChange}
              className="form-input mt-1 block w-full border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-100"
              placeholder="Ingresa el sueldo"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Estado</label>
            <select
              name="status"
              value={technician.status}
              onChange={handleChange}
              className="form-select mt-1 block w-full border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-100"
            >
              <option value="">Selecciona uno</option>
              <option value="active">Activo</option>
              <option value="inactive">Inactivo</option>
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Fecha de Contrato</label>
            <input
              type="date"
              name="hireDate"
              value={technician.hireDate}
              onChange={handleChange}
              className="form-input mt-1 block w-full border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-100"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Tipo de Contrato</label>
            <select
              name="contractType"
              value={technician.contractType}
              onChange={handleChange}
              className="form-select mt-1 block w-full border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-100"
            >
              <option value="">Selecciona uno</option>
              <option value="indefinido">Indefinido</option>
              <option value="temporal">Temporal</option>
              <option value="por obra">Por obra</option>
            </select>
          </div>
        </div>
        <div className="mt-6 flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 dark:bg-gray-700 text-gray-700 dark:text-gray-300 rounded-md"
          >
            Cancelar
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-600 text-white rounded-md"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddTechnicianModal;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import axios from "axios";
import Button from "../components/common/Button";
import { auth } from "../services/firebaseConfig";
import {callFirebaseFunction} from "../services/api";

interface LandingPageContent {
  header: {
    title: string;
    subtitle: string;
  };
  productos: {
    title: string;
    description: string;
    imageUrl: string;
  }[];
  servicios: {
    title: string;
    items: {
      title: string;
      description: string;
    }[];
  };
  proyectos: {
    items: {
      imageUrl: string;
    }[];
  };
}

const ContentEditor: React.FC = () => {
  const darkMode = useSelector((state: RootState) => state.theme.darkMode);
  const [content, setContent] = useState<LandingPageContent | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await callFirebaseFunction({
          endpoint: 'getLandingPageTexts',
          method: 'GET',
        });
        console.log(response)
        setContent(response);
      } catch (err) {
        console.error("Error fetching content:", err);
        setError("Error al cargar el contenido.");
      }
    };

    fetchContent();
  }, []);

  const handleInputChange = (field: string, value: string, section: string, index?: number) => {
    setContent((prev) => {
      if (!prev) return null;

      const newContent = { ...prev };
      if (section === "header") {
        newContent.header = { ...newContent.header, [field]: value };
      } else if (section === "productos" && index !== undefined) {
        newContent.productos[index] = { ...newContent.productos[index], [field]: value };
      } else if (section === "servicios" && index !== undefined) {
        newContent.servicios.items[index] = { ...newContent.servicios.items[index], [field]: value };
      }
      return newContent;
    });
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      await callFirebaseFunction({ endpoint: 'updateLandingPageTexts', body: content, method: 'POST' });
      alert("Contenido actualizado correctamente.");
    } catch (err) {
      console.error("Error al guardar el contenido:", err);
      setError("Error al guardar el contenido.");
    } finally {
      setLoading(false);
    }
  };

  if (!content) return <p>Cargando...</p>;

  return (
    <div className={`min-h-screen ${darkMode ? "dark:bg-gray-900 text-gray-100" : "bg-white text-gray-900"}`}>
      <div className="layout-container flex h-full grow flex-col">
        <div className="px-40 flex flex-1 justify-center py-5">
          <div className="layout-content-container flex flex-col max-w-[960px] flex-1">
            <div className="flex flex-wrap justify-between gap-3 p-4">
              <p className="text-black dark:text-white text-4xl font-black leading-tight tracking-[-0.033em] min-w-72">
                Editor de Contenido
              </p>
            </div>

            {/* Sección Header */}
            <h2 className="text-black dark:text-white font-bold text-2xl px-4 py-3">Header</h2>
            <div className="flex max-w-[480px] flex-wrap items-end gap-4 px-4 py-3">
              <label className="flex flex-col min-w-40 flex-1">
                <p className="text-black dark:text-white text-base font-medium leading-normal pb-2">Título Principal</p>
                <input
                  className="form-input flex w-full min-w-0 flex-1 resize-none overflow-hidden rounded-xl text-black dark:text-white focus:outline-0 focus:ring-0 border border-[#E0E0E0] bg-[#FFFFFF] dark:bg-[#1a1a1a] focus:border-[#E0E0E0] h-14 placeholder:text-neutral-500 p-[15px] text-base font-normal leading-normal"
                  value={content.header.title}
                  onChange={(e) => handleInputChange("title", e.target.value, "header")}
                />
              </label>
            </div>

            <div className="flex max-w-[480px] flex-wrap items-end gap-4 px-4 py-3">
              <label className="flex flex-col min-w-40 flex-1">
                <p className="text-black dark:text-white text-base font-medium leading-normal pb-2">Subtítulo</p>
                <input
                  className="form-input flex w-full min-w-0 flex-1 resize-none overflow-hidden rounded-xl text-black dark:text-white focus:outline-0 focus:ring-0 border border-[#E0E0E0] bg-[#FFFFFF] dark:bg-[#1a1a1a] focus:border-[#E0E0E0] h-14 placeholder:text-neutral-500 p-[15px] text-base font-normal leading-normal"
                  value={content.header.subtitle}
                  onChange={(e) => handleInputChange("subtitle", e.target.value, "header")}
                />
              </label>
            </div>

            {/* Sección Productos */}
            <h2 className="text-black dark:text-white font-bold text-2xl px-4 py-3">Productos</h2>
            <div className="flex flex-wrap gap-6 px-4 py-3">
              {content.productos.map((producto, index) => (
                <div className="w-full sm:w-[calc(33.33%-16px)] flex flex-col gap-4" key={index}>
                  <label className="flex flex-col">
                    <p className="text-black dark:text-white text-base font-medium leading-normal pb-2">Título</p>
                    <input
                      className="form-input w-full resize-none overflow-hidden rounded-xl text-black dark:text-white focus:outline-0 focus:ring-0 border border-[#E0E0E0] bg-[#FFFFFF] dark:bg-[#1a1a1a] focus:border-[#E0E0E0] h-14 placeholder:text-neutral-500 p-[15px] text-base font-normal leading-normal"
                      value={producto.title}
                      onChange={(e) => handleInputChange("title", e.target.value, "productos", index)}
                    />
                  </label>
                  <label className="flex flex-col">
                    <p className="text-black dark:text-white text-base font-medium leading-normal pb-2">Descripción</p>
                    <textarea
                      className="form-input w-full resize-none overflow-hidden rounded-xl text-black dark:text-white focus:outline-0 focus:ring-0 border border-[#E0E0E0] bg-[#FFFFFF] dark:bg-[#1a1a1a] focus:border-[#E0E0E0] min-h-24 placeholder:text-neutral-500 p-[15px] text-base font-normal leading-normal"
                      value={producto.description}
                      onChange={(e) => handleInputChange("description", e.target.value, "productos", index)}
                    />
                  </label>
                </div>
              ))}
            </div>

            {/* Sección Servicios */}
            <h2 className="text-black dark:text-white font-bold text-2xl px-4 py-3">Servicios</h2>
            <div className="flex flex-wrap gap-6 px-4 py-3">
              {content.servicios.items.map((servicio, index) => (
                <div className="w-full sm:w-[calc(33.33%-16px)] flex flex-col gap-4" key={index}>
                  <label className="flex flex-col">
                    <p className="text-black dark:text-white text-base font-medium leading-normal pb-2">Título</p>
                    <input
                      className="form-input w-full resize-none overflow-hidden rounded-xl text-black dark:text-white focus:outline-0 focus:ring-0 border border-[#E0E0E0] bg-[#FFFFFF] dark:bg-[#1a1a1a] focus:border-[#E0E0E0] h-14 placeholder:text-neutral-500 p-[15px] text-base font-normal leading-normal"
                      value={servicio.title}
                      onChange={(e) => handleInputChange("title", e.target.value, "servicios", index)}
                    />
                  </label>
                  <label className="flex flex-col">
                    <p className="text-black dark:text-white text-base font-medium leading-normal pb-2">Descripción</p>
                    <textarea
                      className="form-input w-full resize-none overflow-hidden rounded-xl text-black dark:text-white focus:outline-0 focus:ring-0 border border-[#E0E0E0] bg-[#FFFFFF] dark:bg-[#1a1a1a] focus:border-[#E0E0E0] min-h-24 placeholder:text-neutral-500 p-[15px] text-base font-normal leading-normal"
                      value={servicio.description}
                      onChange={(e) => handleInputChange("description", e.target.value, "servicios", index)}
                    />
                  </label>
                </div>
              ))}
            </div>

            {/* Guardar Cambios */}
            <div className="flex px-4 py-3 justify-center">
              <Button onClick={handleSave} text={loading ? "Guardando..." : "Guardar Cambios"}
                      className="bg-red-500 text-white"/>
            </div>

            {error && <p className="text-red-500">{error}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentEditor;

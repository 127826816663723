import React from 'react';
import Header from '../components/Header/Header';
import { Outlet } from 'react-router-dom';

const MainLayout: React.FC = () => {
  return (
    <div className="min-h-screen bg-slate-50 dark:bg-gray-900 text-gray-900 dark:text-gray-100">
      <Header />
      <main className="p-4 sm:p-6">
        {/* Aquí es donde React Router renderizará las rutas hijas */}
        <Outlet />
      </main>
    </div>
  );
};

export default MainLayout;

import React from "react";
import { signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom"; // Importa useNavigate
import { auth, provider } from "../services/firebaseConfig";

const Login: React.FC = () => {
  const navigate = useNavigate(); // Inicializa useNavigate

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, provider);
      console.log("Usuario autenticado");
      navigate("/"); // Redirige a la página raíz después de iniciar sesión
    } catch (error) {
      console.error("Error al iniciar sesión con Google:", error);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center min-h-screen">
      <img src="/logo-full-v2.png" alt="Logo" className="mb-6 w-6/12 h-auto" />
      <button
        onClick={signInWithGoogle}
        className="bg-blue-500 text-white p-3 rounded-lg"
      >
        Iniciar sesión con Google
      </button>
    </div>
  );
};

export default Login;

import React from 'react';
import { FaSun, FaMoon } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, } from "../../redux/store";
import { toggleDarkMode } from "../../redux/slices/themeSlice";

const DarkModeToggle: React.FC = () => {
  const darkMode = useSelector((state: RootState) => state.theme.darkMode);
  const dispatch = useDispatch();

  const toggleMode = () => {
    dispatch(toggleDarkMode());
  };

  return (
    <div onClick={toggleMode} className="cursor-pointer text-gray-700 dark:text-gray-300">
      {darkMode ? <FaSun size={24} /> : <FaMoon size={24} />}
    </div>
  );
};

export default DarkModeToggle;

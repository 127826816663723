import React from 'react';

const MaintenanceTable: React.FC = () => {
  return (
    <div className="overflow-x-auto rounded-lg shadow mt-6 bg-white dark:bg-gray-900">
      <table className="min-w-full bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100">
        <thead className="bg-gray-50 dark:bg-gray-800">
        <tr>
          <th className="px-6 py-3 border-b text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
            Fecha
          </th>
          <th className="px-6 py-3 border-b text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
            Tipo
          </th>
          <th className="px-6 py-3 border-b text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
            Cliente
          </th>
          <th className="px-6 py-3 border-b text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
            Proveedor
          </th>
          <th className="px-6 py-3 border-b text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
            Estado
          </th>
        </tr>
        </thead>
        <tbody>
        <tr className="bg-white dark:bg-gray-900">
          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-100">
            2022-01-01
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">Mensual</td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">Acme Co</td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">Omnipresence</td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
            <button className="bg-blue-500 text-white px-4 py-2 rounded">Hecho</button>
          </td>
        </tr>
        {/* Otros elementos de la tabla */}
        </tbody>
      </table>
    </div>
  );
};

export default MaintenanceTable;

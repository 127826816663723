import React, { useState } from 'react';

interface AddBuildingModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (building: Building) => void;
}

interface Building {
  name: string;
  address: string;
  owner: string;
  totalElevators: number;
  rut: string; // Nuevo campo para el RUT
}

const AddBuildingModal: React.FC<AddBuildingModalProps> = ({ isOpen, onClose, onSave }) => {
  const [building, setBuilding] = useState<Building>({
    name: '',
    address: '',
    owner: '',
    totalElevators: 0,
    rut: '', // Inicializar el campo RUT
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setBuilding((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = () => {
    onSave(building);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white dark:bg-gray-900 rounded-lg p-6 w-full max-w-md sm:max-w-lg lg:max-w-xl">
        <h2 className="text-xl font-bold mb-4">Agregar Edificio</h2>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Nombre del Edificio</label>
            <input
              type="text"
              name="name"
              value={building.name}
              onChange={handleChange}
              className="form-input mt-1 block w-full border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-100"
              placeholder="Ingresa el nombre"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Dirección</label>
            <input
              type="text"
              name="address"
              value={building.address}
              onChange={handleChange}
              className="form-input mt-1 block w-full border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-100"
              placeholder="Ingresa la dirección"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Propietario</label>
            <input
              type="text"
              name="owner"
              value={building.owner}
              onChange={handleChange}
              className="form-input mt-1 block w-full border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-100"
              placeholder="Ingresa el nombre del propietario"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Total de Ascensores</label>
            <input
              type="number"
              name="totalElevators"
              value={building.totalElevators}
              onChange={handleChange}
              className="form-input mt-1 block w-full border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-100"
              placeholder="Número de ascensores"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">RUT</label>
            <input
              type="text"
              name="rut"
              value={building.rut}
              onChange={handleChange}
              className="form-input mt-1 block w-full border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-100"
              placeholder="Ingresa el RUT"
            />
          </div>
        </div>
        <div className="mt-6 flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 dark:bg-gray-700 text-gray-700 dark:text-gray-300 rounded-md"
          >
            Cancelar
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-blue-600 text-white rounded-md"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddBuildingModal;

import React, { useState, useEffect, CSSProperties } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { Building } from '../types/building';
import { callFirebaseFunction } from '../services/api';
import { PulseLoader } from 'react-spinners';
import {formatCurrency} from "../utils/formatCurrency";

const override: CSSProperties = {
  display: 'block',
  margin: '0 auto',
  borderColor: 'red',
};

const BuildingDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // Obtén el ID del edificio de la URL
  const navigate = useNavigate();
  const darkMode = useSelector((state: RootState) => state.theme.darkMode);
  const [building, setBuilding] = useState<Building | null>(null);
  const [maintenanceHistory, setMaintenanceHistory] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [color] = useState('#891515');

  useEffect(() => {
    const fetchBuildingDetails = async () => {
      try {
        // Obtener detalles del edificio
        const buildingResponse = await callFirebaseFunction({
          endpoint: 'getBuildingById',
          method: 'GET',
          params: { id: id ?? '' },
        });

        setBuilding(buildingResponse);

        // Obtener el historial de mantenciones del edificio
        const maintenanceResponse = await callFirebaseFunction({
          endpoint: 'getBuildingMaintenanceById',
          method: 'GET',
          params: { id: id ?? '' },
        });

        setMaintenanceHistory(maintenanceResponse.maintenance);
      } catch (error) {
        console.error('Error al obtener los detalles del edificio o las mantenciones:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBuildingDetails();
  }, [id]);

  if (loading) {
    return (
      <div className="flex justify-center items-center">
        <PulseLoader color={color} loading={loading} cssOverride={override} size={15} />
      </div>
    );
  }

  if (!building) {
    return (
      <div className="flex justify-center items-center">
        <p>No se encontraron detalles del edificio.</p>
      </div>
    );
  }

  return (
    <div className={`relative flex flex-col min-h-screen ${darkMode ? 'dark' : ''} bg-slate-50 dark:bg-gray-900`}>
      <div className="layout-container flex h-full grow flex-col">
        <div className="px-4 sm:px-8 lg:px-16 flex flex-1 justify-center py-5">
          <div className="layout-content-container flex flex-col w-full max-w-5xl">
            <div className="flex flex-wrap justify-between gap-4 p-4 items-center">
              <div className="flex flex-wrap gap-2 items-center">
                <button
                  className="text-[#4e7397] dark:text-gray-300 text-sm sm:text-base font-medium leading-normal"
                  onClick={() => navigate('/buildings')}
                >
                  Edificios
                </button>
                <span className="text-[#4e7397] dark:text-gray-300 text-sm sm:text-base font-medium leading-normal">/</span>
                <span className="text-[#0e141b] dark:text-gray-100 text-sm sm:text-base font-medium leading-normal">
                  {building.name}
                </span>
              </div>
              <button
                onClick={() => navigate('/buildings')}
                className="text-xs sm:text-sm font-medium text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-700 rounded-lg px-3 py-2"
              >
                Volver
              </button>
            </div>
            <div className="flex flex-col sm:flex-row justify-between gap-4 p-4">
              <div className="flex min-w-full sm:min-w-72 flex-col gap-3">
                <p className="text-[#0e141b] dark:text-gray-100 tracking-light text-2xl sm:text-3xl font-bold leading-tight">
                  {building.name}
                </p>
                <p className="text-[#4e7397] dark:text-gray-300 text-sm font-normal leading-normal">
                  {building.address}
                </p>
                <p className="text-[#4e7397] dark:text-gray-300 text-sm font-normal leading-normal">
                  <strong>Administrador:</strong> {building.owner}
                </p>
                <p className="text-[#4e7397] dark:text-gray-300 text-sm font-normal leading-normal">
                  <strong>RUT:</strong> {building.rut}
                </p>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row gap-4 p-4">
              <div className="flex min-w-full sm:min-w-[158px] flex-1 flex-col gap-2 rounded-xl p-4 bg-[#e7edf3] dark:bg-gray-800">
                <p className="text-[#0e141b] dark:text-gray-100 text-base font-medium leading-normal">
                  Total de Ascensores
                </p>
                <p className="text-[#0e141b] dark:text-gray-100 tracking-light text-xl sm:text-2xl font-bold leading-tight">
                  {building.totalElevators}
                </p>
              </div>
              <div className="flex min-w-full sm:min-w-[158px] flex-1 flex-col gap-2 rounded-xl p-4 bg-[#e7edf3] dark:bg-gray-800">
                <p className="text-[#0e141b] dark:text-gray-100 text-base font-medium leading-normal">
                  Total de Escaleras
                </p>
                <p className="text-[#0e141b] dark:text-gray-100 tracking-light text-xl sm:text-2xl font-bold leading-tight">
                  {building.escalators}
                </p>
              </div>
              <div className="flex min-w-full sm:min-w-[158px] flex-1 flex-col gap-2 rounded-xl p-4 bg-[#e7edf3] dark:bg-gray-800">
                <p className="text-[#0e141b] dark:text-gray-100 text-base font-medium leading-normal">
                  Total de Activos
                </p>
                <p className="text-[#0e141b] dark:text-gray-100 tracking-light text-xl sm:text-2xl font-bold leading-tight">
                  {building.totalAssets}
                </p>
              </div>
            </div>
            <h3 className="text-[#0e141b] dark:text-gray-100 text-lg font-bold leading-tight tracking-[-0.015em] px-4 pb-2 pt-4">
              Historial de Mantenimiento
            </h3>
            <div className="px-4 py-3 overflow-x-auto">
              <div className="min-w-full overflow-hidden rounded-xl border border-[#d0dbe7] dark:border-gray-700 bg-slate-50 dark:bg-gray-900">
                <table className="min-w-full">
                  <thead>
                  <tr className="bg-slate-50 dark:bg-gray-800">
                    <th className="px-4 py-3 text-left text-[#0e141b] dark:text-gray-100 text-xs sm:text-sm font-medium leading-normal">
                      Fecha
                    </th>
                    <th className="px-4 py-3 text-left text-[#0e141b] dark:text-gray-100 text-xs sm:text-sm font-medium leading-normal">
                      Técnico
                    </th>
                    <th className="px-4 py-3 text-left text-[#0e141b] dark:text-gray-100 text-xs sm:text-sm font-medium leading-normal">
                      Costo
                    </th>
                    <th className="px-4 py-3 text-left text-[#0e141b] dark:text-gray-100 text-xs sm:text-sm font-medium leading-normal">
                      Estado
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  {maintenanceHistory.map((item, index) => (
                    <tr key={index} className="border-t border-t-[#d0dbe7] dark:border-gray-700">
                      <td className="h-[72px] px-4 py-2 text-[#4e7397] dark:text-gray-300 text-xs sm:text-sm font-normal leading-normal">
                        {item.date}
                      </td>
                      <td className="h-[72px] px-4 py-2 text-[#0e141b] dark:text-gray-100 text-xs sm:text-sm font-normal leading-normal">
                        {item.technician?.firstName} {item.technician?.lastName}
                      </td>
                      <td className="h-[72px] px-4 py-2 text-[#4e7397] dark:text-gray-300 text-xs sm:text-sm font-normal leading-normal">
                        {formatCurrency(Number(item.cost))}
                      </td>
                      <td className="h-[72px] px-4 py-2">
                        <button className="flex min-w-[84px] max-w-[120px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-8 px-4 bg-[#e7edf3] dark:bg-gray-800 text-[#0e141b] dark:text-gray-100 text-xs sm:text-sm font-medium leading-normal w-full">
                          <span className="truncate">{item.status}</span>
                        </button>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuildingDetail;

import React from 'react';
import Overview from "../components/Dashboard/Overview";
import StatsCard from "../components/Dashboard/StatsCard";
import MaintenanceProgress from "../components/Dashboard/MaintenanceProgress";
import PayrollChart from "../components/Dashboard/PayrollChart";
import MaintenanceTable from "../components/Dashboard/MaintenanceTable";

const Dashboard: React.FC = () => {
  return (
    <div className="p-4 sm:p-6 bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100">
      <Overview title="Resumen" />
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
        <StatsCard title="Mantenimiento Pagado" value="$25,000" />
        <StatsCard title="Mantenimiento Realizado" value="25/50" />
        <StatsCard title="Instalaciones Realizadas" value="3/5" />
        <StatsCard title="Nómina Pagada" value="$10,000" />
      </div>
      <div className="grid grid-cols-1 gap-4 mt-6 lg:grid-cols-2">
        <MaintenanceProgress />
        <PayrollChart />
      </div>
      <MaintenanceTable />
    </div>
  );
};

export default Dashboard;
import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from './Logo';
import ButtonDarkMode from '../common/ButtonDarkMode';  // Botón de cambio de modo oscuro
import { useDispatch } from 'react-redux';
import { signOut } from 'firebase/auth';
import { setAuthenticated } from "../../redux/slices/authSlice";
import { auth } from "../../services/firebaseConfig";
import { FiLogOut } from 'react-icons/fi'; // Importa el ícono de react-icons

const Header: React.FC = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        dispatch(setAuthenticated(false));
      })
      .catch((error) => {
        console.error('Error al cerrar sesión:', error);
      });
  };

  return (
    <header className="flex flex-col sm:flex-row items-center justify-between border-b border-gray-200 dark:border-gray-700 p-4 bg-white dark:bg-gray-900">
      <Logo />
      <nav className="flex flex-wrap justify-center gap-4 mt-4 sm:mt-0">
        <NavLink
          to="/"
          className={({ isActive }) =>
            `text-sm font-medium ${isActive ? 'text-[#c31919]' : 'text-gray-700 dark:text-gray-300'}`
          }
        >
          Inicio
        </NavLink>
        <NavLink
          to="/buildings"
          className={({ isActive }) =>
            `text-sm font-medium ${isActive ? 'text-[#c31919]' : 'text-gray-700 dark:text-gray-300'}`
          }
        >
          Edificios
        </NavLink>
        <NavLink
          to="/technicians"
          className={({ isActive }) =>
            `text-sm font-medium ${isActive ? 'text-[#c31919]' : 'text-gray-700 dark:text-gray-300'}`
          }
        >
          Técnicos
        </NavLink>
        <NavLink
          to="/payments"
          className={({ isActive }) =>
            `text-sm font-medium ${isActive ? 'text-[#c31919]' : 'text-gray-700 dark:text-gray-300'}`
          }
        >
          Pagos
        </NavLink>
        <NavLink
          to="/content-editor"
          className={({ isActive }) =>
            `text-sm font-medium ${isActive ? 'text-[#c31919]' : 'text-gray-700 dark:text-gray-300'}`
          }
        >
          Editar
        </NavLink>
        <ButtonDarkMode />
        <button
          onClick={handleLogout}
          className="text-sm font-medium text-gray-700 dark:text-gray-300 flex items-center"
        >
          <FiLogOut size={24} />
        </button>
      </nav>
    </header>
  );
};

export default Header;

import React from 'react';

const Filters: React.FC = () => {
  return (
    <div className="flex flex-wrap gap-3 p-4">
      <label className="text-sm font-medium leading-normal flex items-center justify-center rounded-xl border border-[#d0dbe7] dark:border-slate-700 px-4 h-11 text-[#0e141b] dark:text-white relative cursor-pointer bg-white dark:bg-slate-900">
        Todos
        <input type="radio" className="invisible absolute" name="payment-filter" />
      </label>
      <label className="text-sm font-medium leading-normal flex items-center justify-center rounded-xl border border-[#d0dbe7] dark:border-slate-700 px-4 h-11 text-[#0e141b] dark:text-white relative cursor-pointer bg-white dark:bg-slate-900">
        Mantenimiento
        <input type="radio" className="invisible absolute" name="payment-filter" />
      </label>
      <label className="text-sm font-medium leading-normal flex items-center justify-center rounded-xl border border-[#d0dbe7] dark:border-slate-700 px-4 h-11 text-[#0e141b] dark:text-white relative cursor-pointer bg-white dark:bg-slate-900">
        Instalación
        <input type="radio" className="invisible absolute" name="payment-filter" />
      </label>
      <label className="text-sm font-medium leading-normal flex items-center justify-center rounded-xl border border-[#d0dbe7] dark:border-slate-700 px-4 h-11 text-[#0e141b] dark:text-white relative cursor-pointer bg-white dark:bg-slate-900">
        General
        <input type="radio" className="invisible absolute" name="payment-filter" />
      </label>
    </div>
  );
};

export default Filters;
import React from 'react';

const MaintenanceProgress: React.FC = () => {
  const data = [
    { month: 'Ene', percentage: 90 },
    { month: 'Feb', percentage: 30 },
    { month: 'Mar', percentage: 60 },
    { month: 'Abr', percentage: 40 },
    { month: 'May', percentage: 60 },
  ];

  return (
    <div className="bg-white dark:bg-gray-900 p-4 rounded-lg shadow">
      <h2 className="text-lg font-medium text-gray-800 dark:text-gray-100 mb-4">Mantenimiento Realizado vs Total</h2>
      <div className="space-y-4">
        {data.map((item) => (
          <div key={item.month} className="flex items-center">
            <span className="text-sm font-bold text-gray-700 dark:text-gray-300 w-12">{item.month}</span>
            <div className="flex-1 h-4 bg-gray-200 dark:bg-gray-700 rounded-lg overflow-hidden ml-2">
              <div
                className="h-full bg-blue-500"
                style={{ width: `${item.percentage}%` }}
              ></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MaintenanceProgress;

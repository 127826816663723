import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Buildings from './pages/Buildings';
import BuildingDetail from './pages/BuildingDetail';
import Technicians from './pages/Technicians';
import TechnicianDetail from './pages/TechnicianDetail';
import MainLayout from './layouts/MainLayout';
import Payments from "./pages/Payments";
import Login from "./pages/Login";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "./redux/store";
import {onAuthStateChanged, signInWithRedirect, signOut} from 'firebase/auth';
import { auth, provider } from './services/firebaseConfig';
import { setAuthenticated } from "./redux/slices/authSlice";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import ContentEditor from "./pages/ContentEditor";

const allowedDomain = "cgelevadores.cl";

const App: React.FC = () => {
  const darkMode = useSelector((state: RootState) => state.theme.darkMode);
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log(user)
      if (user) {
        const email = user.email || '';
        const domain = email.split('@')[1];

        if (domain === allowedDomain) {
          // Si el dominio es correcto, permitimos el acceso
          dispatch(setAuthenticated(true));
        } else {
          // Si el dominio es incorrecto, cerramos la sesión automáticamente
          signOut(auth).then(() => {
            dispatch(setAuthenticated(false));
            alert("Tu cuenta no está autorizada para acceder.");
          });
        }
      } else {
        dispatch(setAuthenticated(false));
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [dispatch]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Routes>
        {/* Redirigir a la página de inicio si ya está autenticado y trata de acceder a /login */}
        <Route path="/login" element={isAuthenticated ? <Navigate to="/" /> : <Login />} />

        {/* Rutas autenticadas */}
        <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
          {/* Este es el MainLayout que contiene las rutas hijas */}
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Dashboard />} />
            <Route path="buildings" element={<Buildings />} />
            <Route path="buildings/:id" element={<BuildingDetail />} />
            <Route path="technicians" element={<Technicians />} />
            <Route path="technicians/:id" element={<TechnicianDetail />} />
            <Route path="payments" element={<Payments />} />
            <Route path="/content-editor" element={<ContentEditor />} />
          </Route>
        </Route>

        {/* Redirigir cualquier otra ruta no existente */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default App;

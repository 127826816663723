import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const PaymentsByTypeChart: React.FC = () => {
  const darkMode = useSelector((state: RootState) => state.theme.darkMode);

  const data = {
    labels: ['Mantenimiento', 'Instalación', 'General'],
    datasets: [
      {
        label: 'Pagos por tipo',
        data: [30000, 20000, 15000],
        backgroundColor: [
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
        ],
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      }
    },
    scales: {
      x: {
        ticks: {
          color: darkMode ? '#ffffff' : '#000000',  // Color de las etiquetas del eje X
        },
        grid: {
          color: darkMode ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.1)',  // Color de las líneas de la cuadrícula
        },
      },
      y: {
        ticks: {
          color: darkMode ? '#ffffff' : '#000000',  // Color de las etiquetas del eje Y
        },
        grid: {
          color: darkMode ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.1)',  // Color de las líneas de la cuadrícula
        },
      },
    },
  };

  return (
    <div className="flex min-w-72 flex-1 flex-col gap-2 rounded-xl border border-[#d0dbe7] p-6">
      <p className="text-[#0e141b] text-base font-medium leading-normal mb-3 dark:text-white">Pagos por tipo</p>
      <div className="flex min-h-[180px] flex-1">
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default PaymentsByTypeChart;

import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const PaymentsByMonthChart: React.FC = () => {
  const darkMode = useSelector((state: RootState) => state.theme.darkMode);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      }
    },
    scales: {
      x: {
        ticks: {
          color: darkMode ? '#ffffff' : '#000000',  // Color de las etiquetas del eje X
        },
        grid: {
          color: darkMode ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.1)',  // Color de las líneas de la cuadrícula
        },
      },
      y: {
        ticks: {
          color: darkMode ? '#ffffff' : '#000000',  // Color de las etiquetas del eje Y
        },
        grid: {
          color: darkMode ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.1)',  // Color de las líneas de la cuadrícula
        },
      },
    },
  };

  const data = {
    labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio'],
    datasets: [
      {
        label: 'Pagos por mes',
        data: [12000, 19000, 3000, 5000, 20000, 30000],
        borderColor: darkMode ? '#1980e6' : '#0e141b',
        backgroundColor: darkMode ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.1)',
        fill: true,
      },
    ],
  };

  return (
    <div className="flex min-w-72 flex-1 flex-col gap-2 rounded-xl border border-[#d0dbe7] p-6">
      <p className="text-[#0e141b] text-base font-medium leading-normal mb-3 dark:text-white">Pagos por mes</p>
      <div className="flex min-h-[180px] flex-1">
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default PaymentsByMonthChart;

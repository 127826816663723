import React, { useState, useEffect, CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from "../components/common/Button";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import AddTechnicianModal from "../components/modals/AddTechnicianModal";
import { callFirebaseFunction } from "../services/api";
import { Technician } from "../types/technician";
import { formatCurrency } from "../utils/formatCurrency";
import { PulseLoader } from "react-spinners";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const TechniciansPage: React.FC = () => {
  const navigate = useNavigate();
  const darkMode = useSelector((state: RootState) => state.theme.darkMode);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [technicians, setTechnicians] = useState<Technician[]>([]);
  const [filteredTechnicians, setFilteredTechnicians] = useState<Technician[]>([]);
  const [loading, setLoading] = useState(true);
  const [color, setColor] = useState("#891515");

  // Estados para búsqueda y filtrado
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('active');
  const [specializationFilter, setSpecializationFilter] = useState('');

  useEffect(() => {
    const fetchTechnicians = async () => {
      try {
        const response = await callFirebaseFunction({ endpoint: 'getTechnicians', method: 'GET' });
        setTechnicians(response);
        setFilteredTechnicians(response); // Inicialmente, muestra todos los técnicos
        setLoading(false);
      } catch (error) {
        console.error('Error al obtener los técnicos:', error);
        setLoading(false);
      }
    };

    fetchTechnicians();
  }, []);

  // Filtrar técnicos cuando el término de búsqueda o los filtros cambian
  useEffect(() => {
    const results = technicians.filter(technician =>
      technician.firstName.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (statusFilter === '' || technician.status === statusFilter) &&
      (specializationFilter === '' || technician.specialization === specializationFilter)
    );
    setFilteredTechnicians(results);
  }, [searchTerm, statusFilter, specializationFilter, technicians]);

  const handleAddTechnician = () => {
    setIsModalOpen(true);
  };

  const handleSaveTechnician = async (technician: any) => {
    try {
      const payload = JSON.stringify(technician);
      console.log("Payload enviado:", payload); // Agrega esta línea para depurar
      const response = await callFirebaseFunction({ endpoint: 'addTechnician', body: payload, method: 'POST' });
      setTechnicians([...technicians, technician]);
      setFilteredTechnicians([...technicians, technician]);
      console.log('Técnico guardado:', response);
    } catch (error) {
      console.error('Error al guardar el técnico:', error);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center">
        <PulseLoader color={color} loading={loading} cssOverride={override} size={15} margin={2} />
      </div>
    );
  }

  if (technicians.length === 0) {
    return (
      <div className="flex justify-center items-center">
        <p className="text-red-500">No se encontró lista de los técnicos.</p>
      </div>
    );
  }

  return (
    <div className="relative flex flex-col bg-slate-50 dark:bg-gray-900" style={{ fontFamily: '"Work Sans", "Noto Sans", sans-serif' }}>
      <div className="layout-container flex h-full grow flex-col">
        <div className="px-4 sm:px-6 lg:px-8 xl:px-16 flex flex-1 justify-center py-5">
          <div className="layout-content-container flex flex-col w-full max-w-5xl">
            <div className="flex flex-wrap justify-between gap-3 p-4 items-center">
              <p className="text-[#0e141b] dark:text-gray-100 tracking-light text-[28px] sm:text-[32px] font-bold leading-tight">Técnicos</p>
              <Button onClick={handleAddTechnician} className="bg-blue-600 text-white px-4 py-2 rounded-lg" text="Agregar Técnico"/>
            </div>

            {/* Barra de búsqueda y filtros */}
            <div className="px-4 py-3">
              <div className="flex flex-wrap gap-4">
                <label className="flex flex-col min-w-40 h-12 w-full">
                  <div className="flex w-full items-stretch rounded-xl h-full">
                    <div className="text-[#4e7397] flex bg-[#e7edf3] dark:bg-gray-700 items-center justify-center pl-1 rounded-l-xl">
                      <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="24px" fill="currentColor" viewBox="0 0 256 256">
                        <path d="M229.66,218.34l-50.07-50.06a88.11,88.11,0,1,0-11.31,11.31l50.06,50.07a8,8,0,0,0,11.32-11.32ZM40,112a72,72,0,1,1,72,72A72.08,72.08,0,0,1,40,112Z"></path>
                      </svg>
                    </div>
                    <input
                      placeholder="Buscar técnicos"
                      className="form-input flex w-full min-w-0 flex-1 resize-none overflow-hidden rounded-r-xl text-[#0e141b] dark:text-gray-100 focus:outline-0 focus:ring-0 bg-[#e7edf3] dark:bg-gray-700 h-full placeholder:text-[#4e7397] px-4"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </label>
                <select
                  className="form-select flex w-full min-w-0 flex-1 resize-none overflow-hidden rounded-xl text-[#0e141b] dark:text-gray-100 focus:outline-0 focus:ring-0 bg-[#e7edf3] dark:bg-gray-700 h-full placeholder:text-[#4e7397] px-4"
                  value={statusFilter}
                  onChange={(e) => setStatusFilter(e.target.value)}
                >
                  <option value="">Filtrar por estado</option>
                  <option value="active">Activo</option>
                  <option value="Despedido">Despedido</option>
                </select>
                <select
                  className="form-select flex w-full min-w-0 flex-1 resize-none overflow-hidden rounded-xl text-[#0e141b] dark:text-gray-100 focus:outline-0 focus:ring-0 bg-[#e7edf3] dark:bg-gray-700 h-full placeholder:text-[#4e7397] px-4"
                  value={specializationFilter}
                  onChange={(e) => setSpecializationFilter(e.target.value)}
                >
                  <option value="">Filtrar por especialización</option>
                  <option value="electrical">Eléctrico</option>
                  <option value="mechanical">Mecánico</option>
                  <option value="hydraulic">Hidráulico</option>
                  {/* Agrega más opciones según las especializaciones disponibles */}
                </select>
              </div>
            </div>

            <div className="px-4 py-3 overflow-x-auto">
              <div className="min-w-full overflow-x-auto">
                <table className="min-w-full">
                  <thead>
                  <tr className="bg-slate-50 dark:bg-gray-800">
                    <th className="px-4 py-3 text-left text-[#0e141b] dark:text-gray-100 text-xs sm:text-sm font-medium leading-normal">Nombre</th>
                    <th className="px-4 py-3 text-left text-[#0e141b] dark:text-gray-100 text-xs sm:text-sm font-medium leading-normal">Salario</th>
                    <th className="px-4 py-3 text-left text-[#0e141b] dark:text-gray-100 text-xs sm:text-sm font-medium leading-normal">Estado</th>
                    <th className="px-4 py-3 text-left text-[#0e141b] dark:text-gray-100 text-xs sm:text-sm font-medium leading-normal">Especialización</th>
                    <th className="px-4 py-3 text-left text-[#0e141b] dark:text-gray-100 text-xs sm:text-sm font-medium leading-normal">Fecha de Contrato</th>
                    <th className="px-4 py-3 text-left text-[#0e141b] dark:text-gray-100 text-xs sm:text-sm font-medium leading-normal">Acciones</th>
                  </tr>
                  </thead>
                  <tbody>
                  {filteredTechnicians.map((technician: Technician) => (
                    <tr key={technician.id} className="border-t border-t-[#d0dbe7] dark:border-gray-700">
                      <td className="h-[72px] px-4 py-2 text-[#0e141b] dark:text-gray-100 text-xs sm:text-sm font-normal leading-normal">{technician.firstName}</td>
                      <td className="h-[72px] px-4 py-2 text-[#4e7397] dark:text-gray-300 text-xs sm:text-sm font-normal leading-normal">{formatCurrency(Number(technician.salary))}</td>
                      <td className="h-[72px] px-4 py-2">
                        <button
                          className={`flex min-w-[84px] max-w-[120px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-8 px-4 ${
                            technician.status === 'active'
                              ? 'bg-green-500 text-white'
                              : 'bg-gray-500 text-white'
                          } text-xs sm:text-sm font-medium leading-normal w-full`}
                        >
                          <span className="truncate">{technician.status === 'active' ? 'Activo' : 'Inactivo'}</span>
                        </button>
                      </td>
                      <td className="h-[72px] px-4 py-2 text-[#0e141b] dark:text-gray-100 text-xs sm:text-sm font-normal leading-normal">{technician.specialization}</td>
                      <td className="h-[72px] px-4 py-2 text-[#4e7397] dark:text-gray-300 text-xs sm:text-sm font-normal leading-normal">{technician.hireDate}</td>
                      <td className="h-[72px] px-4 py-2">
                        <Button
                          onClick={() => navigate(`/technicians/${technician.id}`)}
                          text="Ver Detalles"
                          className={`w-fit text-xs sm:text-sm ${darkMode ? 'bg-gray-700 text-gray-100' : 'bg-gray-200 text-gray-800'}`}
                        />
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddTechnicianModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSaveTechnician}
      />
    </div>
  );
};

export default TechniciansPage;

import React from 'react';
import Button from '../common/Button';

interface OverviewProps {
  title: string;
}

const Overview: React.FC<OverviewProps> = ({ title }) => {
  return (
    <div className="flex justify-between items-center mb-4">
      <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100">{title}</h2>
      <Button
        onClick={() => console.log('Nuevo item creado')}
        text="Nuevo"
        className="w-fit bg-gray-300 dark:bg-gray-800 text-gray-800 dark:text-gray-300"
      />
    </div>
  );
};

export default Overview;

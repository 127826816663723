import { configureStore, createSlice, PayloadAction } from '@reduxjs/toolkit';
import themeSlice from './slices/themeSlice';  // Importa el themeSlice
import authSlice from './slices/authSlice';    // Importa el authSlice

const store = configureStore({
  reducer: {
    theme: themeSlice,  // Agrega el themeSlice al store
    auth: authSlice,    // Agrega el authSlice al store
  },
});

export type RootState = ReturnType<typeof store.getState>;  // Define el tipo RootState
export type AppDispatch = typeof store.dispatch;

export default store;

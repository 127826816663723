import React, { useEffect, useState, CSSProperties } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { Technician } from '../types/technician';
import { callFirebaseFunction } from '../services/api';
import { formatCurrency } from '../utils/formatCurrency';
import { PulseLoader } from 'react-spinners';
import Button from '../components/common/Button';
import ConfirmDeleteModal from "../components/modals/ConfirmDeleteModal";

const override: CSSProperties = {
  display: 'block',
  margin: '0 auto',
  borderColor: 'red',
};

const TechnicianDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const darkMode = useSelector((state: RootState) => state.theme.darkMode);
  const [technician, setTechnician] = useState<Technician | null>(null);
  const [maintenanceHistory, setMaintenanceHistory] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [color, setColor] = useState('#891515');
  const [showConfirmModal, setShowConfirmModal] = useState(false); // Estado para controlar el modal

  useEffect(() => {
    const fetchTechnician = async () => {
      try {
        const response = await callFirebaseFunction({
          endpoint: 'getTechnicianById',
          method: 'GET',
          params: { id: id ?? '' },
        });
        setTechnician(response);
        console.log(technician)
        const maintenanceResponse = await callFirebaseFunction({
          endpoint: 'getMaintenanceByTechnicianId',
          method: 'GET',
          params: { id: id ?? '' },
        });

        setMaintenanceHistory(maintenanceResponse);
        setLoading(false);
      } catch (error) {
        console.error('Error al obtener los datos del técnico:', error);
        setLoading(false);
      }
    };

    fetchTechnician();
  }, [id]);

  const handleDelete = async () => {
    try {
      setLoading(true);
      await callFirebaseFunction({
        endpoint: 'fireTechnician',
        method: 'POST',
        params: { id: id ?? '' },
      });
      setLoading(false);
      setShowConfirmModal(false);
      navigate('/technicians');
    } catch (error) {
      console.error('Error al despedir al técnico:', error);
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center">
        <PulseLoader color={color} loading={loading} cssOverride={override} size={15} margin={2} />
      </div>
    );
  }

  if (!technician) {
    return (
      <div className="flex justify-center items-center">
        <p className="text-red-500">No se encontró información del técnico.</p>
      </div>
    );
  }

  const isFired = technician.status === 'Despedido';

  return (
    <div className={`relative flex size-full min-h-screen flex-col ${darkMode ? 'dark' : ''} bg-slate-50 dark:bg-gray-900`}>
      <div className="layout-container flex h-full grow flex-col">
        <div className="px-4 sm:px-6 lg:px-8 flex flex-1 justify-center py-5">
          <div className="layout-content-container flex flex-col w-full max-w-5xl">
            <div className="flex flex-wrap justify-between gap-4 p-4 items-center">
              <div className="flex flex-wrap gap-2 items-center">
                <button
                  className="text-[#4e7397] dark:text-gray-300 text-base font-medium leading-normal"
                  onClick={() => navigate('/technicians')}
                >
                  Técnicos
                </button>
                <span className="text-[#4e7397] dark:text-gray-300 text-base font-medium leading-normal">/</span>
                <span className="text-[#0e141b] dark:text-gray-100 text-base font-medium leading-normal">
                  {technician.firstName}
                </span>
              </div>
              <div>
                <Button
                  onClick={() => navigate('/technicians')}
                  className="text-xs sm:text-sm font-medium text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-700 rounded-lg px-3 py-2"
                  text="Volver"
                />
                { isFired ||
                  <Button
                    onClick={() => setShowConfirmModal(true)} // Abre el modal de confirmación
                    className="ml-3 text-xs sm:text-sm font-medium text-gray-700 dark:text-gray-300 bg-gray-200 dark:bg-gray-800 hover:bg-gray-300 dark:hover:bg-gray-700 rounded-lg px-3 py-2"
                    text="Eliminar"
                  />}
              </div>
            </div>
            <div className="flex flex-wrap justify-between gap-4 p-4 items-center">
              <div className="flex flex-col gap-3 w-full sm:w-auto">
                <p
                  className="text-[#0e141b] dark:text-gray-100 tracking-light text-[28px] sm:text-[32px] font-bold leading-tight">
                  Detalle del técnico
                </p>
                <p className="text-[#4e7397] dark:text-gray-300 text-sm font-normal leading-normal">
                  Aquí podrás ver toda la información sobre el técnico seleccionado
                </p>
              </div>
            </div>
            {/* Información del técnico */}
            <div className="flex flex-wrap items-start gap-4 p-4">
              <div className="flex items-center gap-4">
                <div
                  className="bg-center bg-no-repeat aspect-square bg-cover rounded-full min-h-24 sm:min-h-32 w-24 sm:w-32"
                  style={{backgroundImage: 'url("https://cdn.usegalileo.ai/stability/91e2c4d6-13a9-4ffd-8e5e-0161376f9b01.png")'}}
                ></div>
                <div className="flex flex-col justify-center">
                  <p
                    className="text-[#0e141b] dark:text-gray-100 text-[22px] sm:text-[28px] font-bold leading-tight tracking-[-0.015em]">
                    {technician.firstName}
                  </p>
                  <p
                    className="text-[#4e7397] dark:text-gray-300 text-base font-normal leading-normal">{technician.specialization}</p>
                  <p
                    className="text-[#4e7397] dark:text-gray-300 text-base font-normal leading-normal">{technician.email} · {technician.phone}</p>
                </div>
              </div>
            </div>

            {/* Modal de confirmación */}
            <ConfirmDeleteModal
              isOpen={showConfirmModal}
              onClose={() => setShowConfirmModal(false)}
              onConfirm={handleDelete}
              technicianName={technician.firstName}
            />

            {/* Historial y estado */}
            <div className="px-4 py-3">
              <h3
                className="text-[#0e141b] dark:text-gray-100 text-lg font-bold leading-tight tracking-[-0.015em] pb-2">
                Estado
              </h3>
              <div className="flex items-center gap-4 bg-slate-50 dark:bg-gray-800 px-4 py-2 rounded-lg">
                <div
                  className="text-[#0e141b] dark:text-gray-100 flex items-center justify-center rounded-lg bg-[#e7edf3] dark:bg-gray-700 p-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor"
                       viewBox="0 0 256 256">
                    <path
                      d="M173.66,98.34a8,8,0,0,1,0,11.32l-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35A8,8,0,0,1,173.66,98.34ZM232,128A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z"></path>
                  </svg>
                </div>
                <div className="flex flex-col">
                  <p
                    className="text-[#0e141b] dark:text-gray-100 text-base font-medium leading-normal">{isFired ? `Activo hasta ${technician.firedDate}` : technician.status}</p>
                  <p
                    className="text-[#4e7397] dark:text-gray-300 text-sm font-normal leading-normal">{isFired ? 'Despedido' : `Activo desde ${technician.hireDate}`}</p>
                </div>
              </div>
            </div>
            <div className="px-4 py-3">
              <h3
                className="text-[#0e141b] dark:text-gray-100 text-lg font-bold leading-tight tracking-[-0.015em] pb-2">
                Historial laboral
              </h3>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="flex flex-col gap-1 border-t border-[#d0dbe7] dark:border-gray-700 py-4">
                  <p className="text-[#4e7397] dark:text-gray-300 text-sm font-normal">Fecha de inicio</p>
                  <p className="text-[#0e141b] dark:text-gray-100 text-sm font-normal">{technician.hireDate}</p>
                </div>
                <div className="flex flex-col gap-1 border-t border-[#d0dbe7] dark:border-gray-700 py-4">
                  <p className="text-[#4e7397] dark:text-gray-300 text-sm font-normal">Contrato</p>
                  <p className="text-[#0e141b] dark:text-gray-100 text-sm font-normal">{technician.contractType}</p>
                </div>
                <div
                  className="flex flex-col gap-1 border-t border-[#d0dbe7] dark:border-gray-700 py-4 sm:col-span-2">
                  <p className="text-[#4e7397] dark:text-gray-300 text-sm font-normal">Salario</p>
                  <p
                    className="text-[#0e141b] dark:text-gray-100 text-sm font-normal">{formatCurrency(Number(technician.salary))}</p>
                </div>
                {isFired && (
                  <div
                    className="flex flex-col gap-1 border-t border-[#d0dbe7] dark:border-gray-700 py-4 sm:col-span-2">
                    <p className="text-[#4e7397] dark:text-gray-300 text-sm font-normal">Fecha de despido</p>
                    <p className="text-[#0e141b] dark:text-gray-100 text-sm font-normal">{technician.firedDate}</p>
                  </div>
                )}
              </div>
            </div>

            {/* Mantenciones */}
            <div className="px-4 py-3 overflow-x-auto">
              <h3
                className="text-[#0e141b] dark:text-gray-100 text-lg font-bold leading-tight tracking-[-0.015em] pb-2">
                Mantenciones
              </h3>
              <div
                className="min-w-full overflow-x-auto rounded-xl border border-[#d0dbe7] dark:border-gray-700 bg-slate-50 dark:bg-gray-800">
                <table className="min-w-full">
                  <thead>
                  <tr className="bg-slate-50 dark:bg-gray-800">
                    <th
                      className="px-4 py-3 text-left text-[#0e141b] dark:text-gray-100 text-xs sm:text-sm font-medium leading-normal">Edificio
                    </th>
                    <th
                      className="px-4 py-3 text-left text-[#0e141b] dark:text-gray-100 text-xs sm:text-sm font-medium leading-normal">Dirección
                    </th>
                    <th
                      className="px-4 py-3 text-left text-[#0e141b] dark:text-gray-100 text-xs sm:text-sm font-medium leading-normal">Costo
                    </th>
                    <th
                      className="px-4 py-3 text-left text-[#0e141b] dark:text-gray-100 text-xs sm:text-sm font-medium leading-normal">Fecha
                    </th>
                    <th
                      className="px-4 py-3 text-left text-[#0e141b] dark:text-gray-100 text-xs sm:text-sm font-medium leading-normal">Estado
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  {maintenanceHistory.map((item, index) => (
                    <tr key={index} className="border-t border-t-[#d0dbe7] dark:border-gray-700">
                      <td
                        className="h-[72px] px-4 py-2 text-[#4e7397] dark:text-gray-300 text-xs sm:text-sm font-normal leading-normal">
                        {item.buildingName}
                      </td>
                      <td
                        className="h-[72px] px-4 py-2 text-[#0e141b] dark:text-gray-100 text-xs sm:text-sm font-normal leading-normal">
                        {item.buildingAddress}
                      </td>
                      <td
                        className="h-[72px] px-4 py-2 text-[#4e7397] dark:text-gray-300 text-xs sm:text-sm font-normal leading-normal">
                        {formatCurrency(Number(item.cost))}
                      </td>
                      <td
                        className="h-[72px] px-4 py-2 text-[#4e7397] dark:text-gray-300 text-xs sm:text-sm font-normal leading-normal">
                        {item.date}
                      </td>
                      <td className="h-[72px] px-4 py-2">
                        <button
                          className="flex min-w-[84px] max-w-[120px] cursor-pointer items-center justify-center overflow-hidden rounded-xl h-8 px-4 bg-[#e7edf3] dark:bg-gray-700 text-[#0e141b] dark:text-gray-100 text-xs sm:text-sm font-medium leading-normal w-full">
                          <span className="truncate">{item.status}</span>
                        </button>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechnicianDetail;

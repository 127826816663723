import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const MonthYearPicker: React.FC = () => {
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
  };

  return (
    <div className="flex flex-col sm:flex-row justify-center items-center gap-4 p-4">
      <div className="flex flex-col">
        <label className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Mes de inicio</label>
        <DatePicker
          selected={startDate}
          onChange={handleStartDateChange}
          dateFormat="MM/yyyy"
          showMonthYearPicker
          className="form-input mt-1 block w-full border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-100"
        />
      </div>
      <div className="flex flex-col">
        <label className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Mes de fin</label>
        <DatePicker
          selected={endDate}
          onChange={handleEndDateChange}
          dateFormat="MM/yyyy"
          showMonthYearPicker
          className="form-input mt-1 block w-full border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-100"
        />
      </div>
    </div>
  );
};

export default MonthYearPicker;

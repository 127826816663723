import React from 'react';

const Logo: React.FC = () => {
  return (
    <div className="flex items-center gap-4 text-[#0e141b] mb-3 sm:mb-0">
      <div className="w-80 h-80 sm:w-80 sm:h-12">
        <img src="/logo-horizontal-v2.png" alt="CG Elevadores SPA" className="w-full h-full object-contain" />
      </div>
    </div>
  );
};

export default Logo;
import React from 'react';

const PaymentsTable: React.FC = () => {
  return (
    <div className="overflow-x-auto w-full px-4 py-3">
      <table className="min-w-full">
        <thead>
        <tr className="bg-slate-50 dark:bg-slate-800">
          <th className="px-4 py-3 text-left text-[#0e141b] dark:text-white text-sm font-medium leading-normal">Fecha</th>
          <th className="px-4 py-3 text-left text-[#0e141b] dark:text-white text-sm font-medium leading-normal">Tipo</th>
          <th className="px-4 py-3 text-left text-[#0e141b] dark:text-white text-sm font-medium leading-normal">Descripción</th>
          <th className="px-4 py-3 text-left text-[#0e141b] dark:text-white text-sm font-medium leading-normal">Edificio</th>
          <th className="px-4 py-3 text-left text-[#0e141b] dark:text-white text-sm font-medium leading-normal">Monto</th>
          <th className="px-4 py-3 text-left text-[#0e141b] dark:text-white text-sm font-medium leading-normal">Estado</th>
          <th className="px-4 py-3 text-left text-[#0e141b] dark:text-white text-sm font-medium leading-normal">Acción</th>
        </tr>
        </thead>
        <tbody>
        <tr className="border-t border-t-[#d0dbe7] dark:border-slate-700">
          <td className="h-[72px] px-4 py-2 text-[#4e7397] dark:text-white text-sm font-normal leading-normal">1 Ene 2022</td>
          <td className="h-[72px] px-4 py-2 text-sm font-normal leading-normal">
            <button className="flex w-full items-center justify-center rounded-xl h-8 px-4 bg-[#e7edf3] dark:bg-slate-700 text-[#0e141b] dark:text-white text-sm font-medium leading-normal">
              <span className="truncate">Mantenimiento</span>
            </button>
          </td>
          <td className="h-[72px] px-4 py-2 text-[#4e7397] dark:text-white text-sm font-normal leading-normal">Cuota mensual de mantenimiento</td>
          <td className="h-[72px] px-4 py-2 text-[#4e7397] dark:text-white text-sm font-normal leading-normal">123 Calle Principal</td>
          <td className="h-[72px] px-4 py-2 text-[#4e7397] dark:text-white text-sm font-normal leading-normal">$500</td>
          <td className="h-[72px] px-4 py-2 text-sm font-normal leading-normal">
            <button className="flex w-full items-center justify-center rounded-xl h-8 px-4 bg-[#e7edf3] dark:bg-slate-700 text-[#0e141b] dark:text-white text-sm font-medium leading-normal">
              <span className="truncate">Pagado</span>
            </button>
          </td>
          <td className="h-[72px] px-4 py-2 text-[#4e7397] dark:text-white text-sm font-bold leading-normal tracking-[0.015em]">
            Ver
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PaymentsTable;